/* unbounded-cyrillic-ext-200-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(/static/media/unbounded-cyrillic-ext-200-normal.a48bedc1.woff2) format('woff2'), url(/static/media/unbounded-all-200-normal.cdcb258b.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* unbounded-cyrillic-200-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(/static/media/unbounded-cyrillic-200-normal.98d583cb.woff2) format('woff2'), url(/static/media/unbounded-all-200-normal.cdcb258b.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* unbounded-vietnamese-200-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(/static/media/unbounded-vietnamese-200-normal.f55b5b64.woff2) format('woff2'), url(/static/media/unbounded-all-200-normal.cdcb258b.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* unbounded-latin-ext-200-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(/static/media/unbounded-latin-ext-200-normal.44474531.woff2) format('woff2'), url(/static/media/unbounded-all-200-normal.cdcb258b.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* unbounded-latin-200-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(/static/media/unbounded-latin-200-normal.c3692fd6.woff2) format('woff2'), url(/static/media/unbounded-all-200-normal.cdcb258b.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* unbounded-cyrillic-ext-300-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/unbounded-cyrillic-ext-300-normal.3a8054c3.woff2) format('woff2'), url(/static/media/unbounded-all-300-normal.e0af1463.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* unbounded-cyrillic-300-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/unbounded-cyrillic-300-normal.5e888a65.woff2) format('woff2'), url(/static/media/unbounded-all-300-normal.e0af1463.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* unbounded-vietnamese-300-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/unbounded-vietnamese-300-normal.60f3e43c.woff2) format('woff2'), url(/static/media/unbounded-all-300-normal.e0af1463.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* unbounded-latin-ext-300-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/unbounded-latin-ext-300-normal.a8202ef9.woff2) format('woff2'), url(/static/media/unbounded-all-300-normal.e0af1463.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* unbounded-latin-300-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/unbounded-latin-300-normal.384ec4ad.woff2) format('woff2'), url(/static/media/unbounded-all-300-normal.e0af1463.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* unbounded-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/unbounded-cyrillic-ext-400-normal.feedae10.woff2) format('woff2'), url(/static/media/unbounded-all-400-normal.392725f5.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* unbounded-cyrillic-400-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/unbounded-cyrillic-400-normal.bc3b87a6.woff2) format('woff2'), url(/static/media/unbounded-all-400-normal.392725f5.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* unbounded-vietnamese-400-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/unbounded-vietnamese-400-normal.6849a057.woff2) format('woff2'), url(/static/media/unbounded-all-400-normal.392725f5.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* unbounded-latin-ext-400-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/unbounded-latin-ext-400-normal.bbbf9e6e.woff2) format('woff2'), url(/static/media/unbounded-all-400-normal.392725f5.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* unbounded-latin-400-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/unbounded-latin-400-normal.f171eb1e.woff2) format('woff2'), url(/static/media/unbounded-all-400-normal.392725f5.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* unbounded-cyrillic-ext-500-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/unbounded-cyrillic-ext-500-normal.64f90cfe.woff2) format('woff2'), url(/static/media/unbounded-all-500-normal.1bf6ccbe.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* unbounded-cyrillic-500-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/unbounded-cyrillic-500-normal.d1030b7f.woff2) format('woff2'), url(/static/media/unbounded-all-500-normal.1bf6ccbe.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* unbounded-vietnamese-500-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/unbounded-vietnamese-500-normal.b1880352.woff2) format('woff2'), url(/static/media/unbounded-all-500-normal.1bf6ccbe.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* unbounded-latin-ext-500-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/unbounded-latin-ext-500-normal.2297fe1b.woff2) format('woff2'), url(/static/media/unbounded-all-500-normal.1bf6ccbe.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* unbounded-latin-500-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/unbounded-latin-500-normal.5d66157c.woff2) format('woff2'), url(/static/media/unbounded-all-500-normal.1bf6ccbe.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* unbounded-cyrillic-ext-600-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/unbounded-cyrillic-ext-600-normal.94b4d0dc.woff2) format('woff2'), url(/static/media/unbounded-all-600-normal.c2037e08.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* unbounded-cyrillic-600-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/unbounded-cyrillic-600-normal.1086fef0.woff2) format('woff2'), url(/static/media/unbounded-all-600-normal.c2037e08.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* unbounded-vietnamese-600-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/unbounded-vietnamese-600-normal.346c4f2e.woff2) format('woff2'), url(/static/media/unbounded-all-600-normal.c2037e08.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* unbounded-latin-ext-600-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/unbounded-latin-ext-600-normal.f7bd255f.woff2) format('woff2'), url(/static/media/unbounded-all-600-normal.c2037e08.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* unbounded-latin-600-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/static/media/unbounded-latin-600-normal.3183d313.woff2) format('woff2'), url(/static/media/unbounded-all-600-normal.c2037e08.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* unbounded-cyrillic-ext-700-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/unbounded-cyrillic-ext-700-normal.e56c7072.woff2) format('woff2'), url(/static/media/unbounded-all-700-normal.ffbd8f3c.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* unbounded-cyrillic-700-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/unbounded-cyrillic-700-normal.49b35e01.woff2) format('woff2'), url(/static/media/unbounded-all-700-normal.ffbd8f3c.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* unbounded-vietnamese-700-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/unbounded-vietnamese-700-normal.1a750958.woff2) format('woff2'), url(/static/media/unbounded-all-700-normal.ffbd8f3c.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* unbounded-latin-ext-700-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/unbounded-latin-ext-700-normal.f867c4db.woff2) format('woff2'), url(/static/media/unbounded-all-700-normal.ffbd8f3c.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* unbounded-latin-700-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/unbounded-latin-700-normal.41ad8b0d.woff2) format('woff2'), url(/static/media/unbounded-all-700-normal.ffbd8f3c.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* unbounded-cyrillic-ext-800-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/unbounded-cyrillic-ext-800-normal.038aceb3.woff2) format('woff2'), url(/static/media/unbounded-all-800-normal.78fbb4a5.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* unbounded-cyrillic-800-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/unbounded-cyrillic-800-normal.2b27f6d0.woff2) format('woff2'), url(/static/media/unbounded-all-800-normal.78fbb4a5.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* unbounded-vietnamese-800-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/unbounded-vietnamese-800-normal.9b87cf8e.woff2) format('woff2'), url(/static/media/unbounded-all-800-normal.78fbb4a5.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* unbounded-latin-ext-800-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/unbounded-latin-ext-800-normal.79867108.woff2) format('woff2'), url(/static/media/unbounded-all-800-normal.78fbb4a5.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* unbounded-latin-800-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/static/media/unbounded-latin-800-normal.86a2d801.woff2) format('woff2'), url(/static/media/unbounded-all-800-normal.78fbb4a5.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* unbounded-cyrillic-ext-900-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/static/media/unbounded-cyrillic-ext-900-normal.4bb42d29.woff2) format('woff2'), url(/static/media/unbounded-all-900-normal.d5290fb3.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* unbounded-cyrillic-900-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/static/media/unbounded-cyrillic-900-normal.89a8d82a.woff2) format('woff2'), url(/static/media/unbounded-all-900-normal.d5290fb3.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* unbounded-vietnamese-900-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/static/media/unbounded-vietnamese-900-normal.f09dba09.woff2) format('woff2'), url(/static/media/unbounded-all-900-normal.d5290fb3.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* unbounded-latin-ext-900-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/static/media/unbounded-latin-ext-900-normal.2cf9ee21.woff2) format('woff2'), url(/static/media/unbounded-all-900-normal.d5290fb3.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* unbounded-latin-900-normal*/
@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/static/media/unbounded-latin-900-normal.fd11b620.woff2) format('woff2'), url(/static/media/unbounded-all-900-normal.d5290fb3.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

